import { Link } from 'react-router-dom';
import './footer.css';
import * as React from 'react';
//import logo from './dotech-logo.webp'

function Footer(){

const [value, setValue] = React.useState(0);

    return (
            
        <>
        <div className="footer">
              <div className='nav-bottom'>
                    <ul>
                        <li><Link className='cts-links' to='/'>Home</Link>|</li>
                        <li><Link className='cts-links'to='/About'>About</Link>|</li>
                        <li><Link className='cts-links' to='/Gallery'>Our Collection</Link>|</li>
                        <li><Link className='cts-links 'to='/Contact'>Contact</Link>|</li>
                    </ul>
                    <hr></hr>
                </div>
           
            <div className="copyright-box">
                <p>Copyright © REHOBOTH SHELTERS . All rights reserved</p>
                    <div className='cta-image-1'><p>Powered By: <h4>Dot.ech</h4> </p></div>
            </div>
        </div>
        <div className='bottom-navbar'>
            <div className='wrapper-bottom-navbar'>
                <div className='cta-link-bottom-bar'><a  href="tel:+91 8524884488"><i class="bi bi-telephone-forward"></i></a></div>
                    <div className='cta-link-bottom-bar'><a href="https://maps.app.goo.gl/8Rt2ejZt56kybRcm7"><i class="bi bi-geo-alt"></i></a></div>
                    <div className='cta-link-bottom-bar'><a href="tel:+91 77088 98999"><i class="bi bi-whatsapp"></i></a></div>
                <div className='cta-link-bottom-bar'><a href='https://www.instagram.com/rehoboth_shelters'><i class="bi bi-instagram"></i></a></div>
            </div>
        </div>
        </>
    );

}


export default Footer;
