
import image3 from './assets/images/carosel-img.webp'
import image2 from './assets/images/carosel-img-2.webp'
import image1 from './assets/images/carosel-img-3.webp'
import './styles/home.css'
import data from "./data";
import  LogoCarousel from './logo-component'

function Home(){

  (function() {

    "use strict";
  
    window.onload = function() {
  
      var images = document.querySelectorAll(".media-images.media-images--config-dynamic .media-images__image");
  
      function adjustImageWidth(image) {
        var widthBase   = 50;
        var scaleFactor = 0.525;
        var imageRatio  = image.naturalWidth / image.naturalHeight;
  
        image.width = Math.pow(imageRatio, scaleFactor) * widthBase;
      }
  
      images.forEach(adjustImageWidth);
  
    };
  
  }());
  
return(
<>
<div className="container-1">
<div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="5000">
      <img src={image1}class="d-block h-100 w-100" alt="..."/>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src={image2} class="d-block h-50 w-100" alt="..."/>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src={image3} class="d-block h-50 w-100" alt="..."/>
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
<div className='container-2'>
    <h1>Welcome to Rehoboth Shelters - Your Premier Destination for Quality Tiles! </h1>
    <p>At Rehoboth Shelters, we take immense pride in being your trusted source for top-notch tiles from some of the most renowned brands in the industry. With a passion for excellence and a commitment to delivering only the best, we've curated an extensive collection of tiles that cater to a diverse range of tastes, preferences, and project needs. Our mission is to transform your spaces into captivating masterpieces, and we do so by offering tiles from leading brands such as Harsha Tiles, Kajaria, Nitco, Somany, Simpolo, and many more.</p>
   </div>
<div className='container-3'>
    <h2 className='cta-title-1'>Explore Our Premium Brands:</h2>
    <div className='cta-our-brands'>
      <div className='cta-brand'>
        <img src={data[0].img}></img>
        <div className='cta-texts'>
          <h4>{data[0].title}</h4>
          <p>{data[0].para}</p>
        </div>
      </div>
      <div className='cta-brand'>
         <div className='cta-texts'>
          <h4>{data[1].title}</h4>
          <p>{data[1].para}</p>
        </div>
        <img src={data[1].img}></img>
      </div>
      <div className='cta-brand'>
      <img src={data[2].img}></img>
      <div className='cta-texts'>
          <h4>{data[2].title}</h4>
          <p>{data[2].para}</p>
        </div>
        </div>
      <div className='cta-brand'>
      <div className='cta-texts'>
          <h4>{data[3].title}</h4>
          <p>{data[3].para}</p>
        </div><img src={data[3].img}></img>  
      </div>
      <div className='cta-brand'>
      <img src={data[4].img}></img>
      <div className='cta-texts'>
          <h4>{data[4].title}</h4>
          <p>{data[4].para}</p>
        </div>  
      </div>
      <div className='cta-brand'>
      <div className='cta-texts'>
          <h4>{data[5].title}</h4>
          <p>{data[5].para}</p>
        </div><img src={data[5].img}></img>  
      </div>
      <div className='cta-brand'>
      <img src={data[6].img}></img>
      <div className='cta-texts'>
          <h4>{data[6].title}</h4>
          <p>{data[6].para}</p>
        </div>  
      </div>

      <hr></hr>
    </div>
    
</div>
<div className='Container-4'>
<section class="slider">
      <div class="logo-slider">
        <div>
        {data.map((values)=>{
            return(
              <>
              <img className="cta-slide" src={values.brandimg}></img>
              </>
            );
          })}
        </div>
      </div>
            </section>
            <center><p> REHOBOTH SHELTERS . All rights reserved</p></center>
            <center><p>Powered By:</p><h4>Dot.ech</h4></center>
        </div>
</>
);
}
export default Home;