import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Data from '../data';
import './assets/style/style.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Product() {
  const { id } = useParams();
  const [filteredProduct, setFilteredProduct] = useState(null);

  useEffect(() => {
    // Simulating asynchronous data loading
    const selectedProduct = Data.find((product) => product.id.toString() === id);
    setFilteredProduct(selectedProduct);
  }, [id]);

  return (
    <>
      {filteredProduct ? (
        <div  className='cta-wrapper' key={filteredProduct.id}>
          <div className='cta-container-1'>
            <div className='cta-text-1'> 
              <h1>{filteredProduct.title}:</h1>
              <p>{filteredProduct.para}</p>
            </div>
            <div className="cta-img">
              <img src={filteredProduct.img}></img>
            </div>
          </div>
          <div className='cta-container-2'>
          <Accordion className="cta-Accordions">
            <AccordionSummary className="cta-Accordions-summary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="cta-Accordions-summary-text" >{filteredProduct.accordiantitle}</Typography>
            </AccordionSummary>
            <AccordionDetails className="cta-Accordion-details">
              <Typography  className="cta-Accordion-details-text">
                {filteredProduct.accordiansummary}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="cta-Accordions">
            <AccordionSummary className="cta-Accordions-summary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="cta-Accordions-summary-text">{filteredProduct.accordiantitle1}</Typography>
            </AccordionSummary>
            <AccordionDetails className="cta-Accordion-details">
              <Typography className="cta-Accordion-details-text">
                {filteredProduct.accordiansummary1}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="cta-Accordions">
            <AccordionSummary className="cta-Accordions-summary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="cta-Accordions-summary-text">{filteredProduct.accordiantitle2}</Typography>
            </AccordionSummary>
            <AccordionDetails className="cta-Accordion-details">
              <Typography className="cta-Accordion-details-text">
                {filteredProduct.accordiansummary2}
              </Typography>
            </AccordionDetails>
          </Accordion>
         
          </div>
        </div>
      ) : (
        <p>No product found for the selected ID.</p>
      )}
    </>
  );
}

export default Product;
