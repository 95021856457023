import image1 from "./assets/images/brand1.webp";
import image2 from "./assets/images/brand2.webp";
import image3 from "./assets/images/brand3.webp";
import image4 from "./assets/images/brand4.webp";
import image5 from "./assets/images/brand5.webp";
import image6 from "./assets/images/brand6.webp";
import image7 from "./assets/images/brand7.webp";

import brandimg1 from "./assets/images/brand-logo1.png";
import brandimg2 from "./assets/images/brand-logo2.png";
import brandimg3 from "./assets/images/brand-logo3.png";
import brandimg4 from "./assets/images/brand-logo4.png";
import brandimg5 from "./assets/images/brand-logo5.png";
import brandimg6 from "./assets/images/brand-logo6.png";
import brandimg7 from "./assets/images/brand-logo7.png";
import brandimg8 from "./assets/images/brand-logo8.png";



const brands =[
    {
        id:1,
        title:"Harsha Tiles:",
        para:"Discover a world of endless possibilities with Harsha Tiles. As your authorized reseller, we proudly offer a handpicked selection of Harsha Tiles' finest creations. Renowned for their impeccable quality and innovative designs, Harsha Tiles redefines the way you envision your spaces. Crafted with precision and durability in mind, these exceptional ceramic tiles are not just a surface; they are a canvas for your creativity. Whether you're designing for residential, commercial, or industrial spaces, Harsha Tiles will stand the test of time, making your vision a timeless reality.",
        img:image1,
        brandimg:brandimg1,
    },
    {
        id:2,
        title:"Kajaria:",
        para:"Elevate your interiors to the pinnacle of luxury and style with Kajaria Tiles. Kajaria is more than just a brand; it's an embodiment of sophistication and elegance. Offering a myriad of options that cater to every aesthetic, Kajaria Tiles transforms ordinary spaces into extraordinary experiences. From the timeless charm of classic designs to the bold statements of contemporary patterns, Kajaria Tiles redefines what luxury means to you. Let your imagination run wild and create spaces that reflect your unique personality and taste.",
        img:image2,
        brandimg:brandimg2,
    },
    {
        id:3,
        title:"Nitco:",
        para:"For decades, Nitco Tiles has been synonymous with excellence in craftsmanship and design. Each Nitco tile is a masterpiece, meticulously crafted to perfection. The result is a collection of tiles that exude a timeless appeal, adding an aura of elegance and charm to your spaces. Explore the diverse range of Nitco Tiles and infuse your interiors with an enduring sense of sophistication. From flooring that's a pleasure to walk on to wall tiles that make a statement, Nitco Tiles epitomize enduring quality and style.",
        img:image3,
        brandimg:brandimg3,
    },
    {
        id:4,
        title:"Somany:",
        para:"Somany Ceramics is a name that resonates with innovation and uncompromising quality. Their vast array of designs, finishes, and textures empowers you to bring your design visions to life. Whether you're aiming for a classic look or pushing the boundaries of contemporary design, Somany Tiles provides the perfect canvas for your creativity. With Somany, your spaces become a reflection of your personality, inviting, inspiring, and truly unique. Discover a world of possibilities, and let Somany Tiles be the catalyst for your design dreams.",
        img:image4,
        brandimg:brandimg4,
    },
    {
        id:5,
        title:"Simpolo:",
        para:"Simpolo Tiles are designed to meet the dynamic needs of modern living spaces. They're more than just tiles; they're a fusion of style and functionality. Simpolo Tiles are the embodiment of contemporary design, offering sleek, innovative patterns and finishes that add a touch of sophistication to your projects. Whether it's a residential renovation or a commercial venture, Simpolo Tiles redefines modern aesthetics. Choose Simpolo for spaces that are not only visually stunning but also engineered for today's demanding lifestyles.",
        img:image5,
        brandimg:brandimg5,
    },
    {
        id:6,
        title:" Jaquar: ",
        para:"Jaquar is a revered name synonymous with luxury and innovation in the world of bathroom solutions. With a heritage of excellence spanning over three decades, Jaquar offers an extensive range of meticulously crafted products, including faucets, showers, sanitaryware, and more. Elevate your bathroom to a new level of sophistication with Jaquar's impeccable craftsmanship and cutting-edge designs. Whether you seek classic elegance or contemporary chic, Jaquar has the perfect solution to transform your bathroom into a sanctuary of style and functionality.",
        img:image6,
        brandimg:brandimg6,
    },
    {
        id:7,
        title:'Kerovit: ',
        para:"Kerovit introduces contemporary elegance and functionality to your bathroom space. Their wide-ranging products, encompassing faucets, sanitaryware, and accessories, are thoughtfully designed to cater to modern design sensibilities. Kerovit's unwavering commitment to quality and style empowers you to create a bathroom that reflects your unique taste and lifestyle. From innovative faucet designs to ergonomically crafted sanitaryware, Kerovit offers a diverse selection to infuse your bathroom with modern charm and practicality. Explore Kerovit to elevate your bathroom experience to new heights of aesthetic and functional excellence.",
        img:image7,
        brandimg:brandimg7,
    },
    {
        id:8,
        brandimg:brandimg8,
        
    },
    {
        id:9,
        brandimg:brandimg1,
        
    },
    {
        id:10,
        brandimg:brandimg2,
        
    },
    {
        id:11,
        brandimg:brandimg3,
        
    },
    {
        id:12,
        brandimg:brandimg4,
        
    },
    {
        id:13,
        brandimg:brandimg5,
        
    },
    {
        id:14,
        brandimg:brandimg6,
        
    },
    {
        id:15,
        brandimg:brandimg7,
        
    },
    {
        id:16,
        brandimg:brandimg8,
        
    },
    {
        id:17,
        brandimg:brandimg1,
        
    },
    {
        id:18,
        brandimg:brandimg2,
        
    },
    {
        id:19,
        brandimg:brandimg3,
        
    },
    {
        id:20,
        brandimg:brandimg4,
        
    },
    {
        id:21,
        brandimg:brandimg5,
        
    },
    {
        id:22,
        brandimg:brandimg6,
        
    },
    {
        id:23,
        brandimg:brandimg7,
        
    },
    {
        id:24,
        brandimg:brandimg8,
        
    },

]

export default brands;