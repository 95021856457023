import './style/navbar.css';
import image from './assets/images/logo-image.png';
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Data from '../../page/data';


const Navbar = () => {
  const [idvalue, setidvalue] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductListOpen, setIsProductListOpen] = useState(false); // Add product list state

  const Getid = (id) => {
    setidvalue(id);
    setIsMenuOpen(false); // Close the main menu after selecting an item
    setIsProductListOpen(false); // Close the product list after selecting a product
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsProductListOpen(false); // Close the product list when toggling the main menu
  };

  const toggleProductList = () => {
    setIsProductListOpen(!isProductListOpen);
  };

  return (
    <div className={`navigation-bar-main ${isMenuOpen ? 'menu-open' : ''}`}>
         <div className="header-1">
            <div className="cta-image-logo">
              <img herf=""src={image} alt="Logo" />
            </div>
            <div className='cta-icons'>
              <i className="bi bi-telephone-fill"></i>
              <a  href="tel:+91 8524884488"> +91 8524884488</a>
            </div>
            <div className='cta-icons'>
            <i class="bi bi-whatsapp"></i>
              <a href="tel:+91 77088 98999">+91 77088 98999</a>
            </div>
            <div>
              <i className="bi bi-envelope-at-fill"></i>
              <a href="mailto:rehobothtilesmtp@gmail.com">rehobothtilesmtp@gmail.com</a>
            </div>
            <div className='cta-links-insta'>
              
              <a href="https://www.instagram.com/rehoboth_shelters"><i id='cta-links-insta' className="bi bi-instagram"></i></a>
              
            </div>
         </div>
      <div className="header-2">
        <div className="menu-icon" onClick={toggleMenu}>
          <i className={`bi ${isMenuOpen ? 'bi-x' : 'bi-list'}`}></i>
        </div>
        <nav className={`navbar ${isMenuOpen ? 'menu-open' : ''}`}>
          <div className="navbar-center">
            <ul className="menu">
              <li className="cta-links"><Link to='/' onClick={toggleMenu}>HOME</Link></li>
              <li className="cta-links"><Link to='/About' onClick={toggleMenu}>ABOUT</Link></li>
              <li className="product-menu">
                {/* Toggle the product list on click */}
                <label className="cta-links" onClick={toggleProductList}>
                  PRODUCTS 
                </label>
                {/* Display product list based on state */}
                <div className={`product-list ${isProductListOpen ? 'show' : ''}`}>
                  <div className="product-grid">
                    {Data.map((values) => (
                      <Link
                        to={`/Product/${values.id}`}
                        className="product-item"
                        key={values.id}
                      >
                        <div
                          className="cta-titles"
                          onClick={() => Getid(values.id)}
                          key={values.id}
                        >
                          {values.title}
                        
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </li>
              <li className="cta-links"><Link to='/Gallery' onClick={toggleMenu}>OUR COLLECTION</Link></li>
              <li className="cta-links"><Link to='/Contact' onClick={toggleMenu}>CONTACT</Link></li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
