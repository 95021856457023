import './App.css'
import Navbar from "./components/header/navbar";
import Home from './page/home/home';
import About from './page/about/about';
import Product from './page/product/product';
import  Gallery from './page/gallery/gallery';
import Contact from './page/contact/contact';
import Footer from './components/footer/footer';
import {  Routes, Route } from "react-router-dom";
import { createContext } from 'react';

function App() {

  const Nav = createContext();

  return (
      <>
     
       <Navbar />
          <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/About' element={<About/>}></Route>
          <Route path='/Product/:id' element={<Product/>}></Route>
          <Route path='/Gallery' element={<Gallery/>}></Route>
          <Route path='/Contact' element={<Contact/>}></Route>
          </Routes> 
        <Footer/>
      </>    
  );
}

export default App;
