import image1 from './product/assets/image-product (1).webp'
import image2 from './product/assets/image-product (2).webp'
import image3 from './product/assets/image-product (3).webp'
import image4 from './product/assets/image-product (4).webp'
import image5 from './product/assets/image-product (5).webp'

const Data =[
    {
    id:1,
    title:'Wall Tiles ',
    para:"Elevate your living spaces to new heights of sophistication with our exquisite collection of wall tiles. Designed to transcend the ordinary, our wall tiles are the perfect canvas for your design visions. Whether you're crafting a tranquil bathroom, a functional yet stylish kitchen, an inviting outdoor area, a cozy living room, a peaceful bedroom, or a sleek commercial space, our wall tiles offer the ultimate fusion of style and functionality. With a wide range of textures, patterns, and finishes, our wall tiles empower you to transform any space into a work of art, all while providing the durability and practicality you need.",
    img:image5,
    accordiantitle:'What types of tiles do you offer?',
    accordiansummary:'We offer a wide range of tiles, including ceramic, porcelain, glass, mosaic, and natural stone tiles.',
    accordiantitle1:'How do I choose the right tile for my project?',
    accordiansummary1:"Our experts can help you choose the perfect tile based on your project's needs, including style, size, and durability requirements.'",      
    accordiantitle2:'Are your tiles easy to maintain?',
    accordiansummary2:'Most of our tiles are easy to clean and maintain. We provide care and maintenance recommendations for each tile type.',
    },
    {
        id:2,
        title:'Floor Tiles ',
        para:"Turn your floors into statements of luxury and durability with our premium floor tiles. Ideal for a wide range of spaces, including your inviting living room, outdoor areas that withstand the elements, your tranquil bedroom, a kitchen that combines beauty and practicality, a bathroom with a touch of spa-like luxury, or a hardworking commercial space, our floor tiles are crafted to provide not only stunning aesthetics but also the utmost comfort underfoot. Whether you're aiming for a timeless look or a modern statement, our floor tiles offer a versatile foundation for your interior or exterior design dreams. Explore our selection and experience the perfect blend of aesthetics and functionality with our floor tiles.",
        img:image3,
        accordiantitle:"What's the difference between ceramic and porcelain tiles?",
        accordiansummary:'Ceramic tiles are made of clay and are typically suitable for indoor use. Porcelain tiles are denser and more durable, making them suitable for both indoor and outdoor applications. ',
        accordiantitle1:'Do you offer eco-friendly tile options?',
        accordiansummary1:"Yes, we have eco-friendly and sustainable tile options, including recycled materials and low-VOC products.",      
        accordiantitle2:"What is the expected delivery time for tile orders?",
        accordiansummary2:'We provide estimated delivery times for each order. Contact our customer service for specific information.',
    },
    {
        id:3,
        title:'Elevation Tiles',
        para:"Elevate the façade of your building to a new level of elegance with our stunning collection of elevation tiles. These tiles are specially designed to enhance the exterior appearance of your home or commercial property. Whether you're renovating a residential space or designing a commercial project, our elevation tiles offer a perfect blend of aesthetics and durability. Create a memorable first impression with a facade that boasts exquisite textures, patterns, and finishes. Our elevation tiles are not just a surface; they are a statement, transforming your property into a work of architectural art.",
        img:image2,
        accordiantitle:'What types of tiles do you offer?',
        accordiansummary:'We offer a wide range of tiles, including ceramic, porcelain, glass, mosaic, and natural stone tiles.',
        accordiantitle1:'How do I choose the right tile for my project?',
        accordiansummary1:"Our experts can help you choose the perfect tile based on your project's needs, including style, size, and durability requirements.'",      
        accordiantitle2:'Are your tiles easy to maintain?',
        accordiansummary2:'Most of our tiles are easy to clean and maintain. We provide care and maintenance recommendations for each tile type.',
    },
    {
        id:4,
        title:'Outdoor Tiles',
        para:"Turn your outdoor spaces into inviting retreats with our durable and stylish outdoor tiles. Designed to withstand the elements, these tiles are perfect for patios, decks, gardens, walkways, and other outdoor areas. Whether you're hosting outdoor gatherings, enjoying a quiet moment in your garden, or creating a functional outdoor dining area, our outdoor tiles provide the foundation for beautiful and practical outdoor living. Choose from a range of slip-resistant textures, natural stone looks, and contemporary designs to create an outdoor space that seamlessly blends with the surrounding environment. Discover the perfect combination of aesthetics and longevity with our outdoor tiles.",
        img:image4,
        accordiantitle:'What types of tiles do you offer?',
        accordiansummary:'We offer a wide range of tiles, including ceramic, porcelain, glass, mosaic, and natural stone tiles.',
        accordiantitle1:'How do I choose the right tile for my project?',
        accordiansummary1:"Our experts can help you choose the perfect tile based on your project's needs, including style, size, and durability requirements.'",      
        accordiantitle2:'Are your tiles easy to maintain?',
        accordiansummary2:'Most of our tiles are easy to clean and maintain. We provide care and maintenance recommendations for each tile type.',
    },
    {
        id:5,
        title:'Bathroom Essentials',
        para:"Explore our carefully curated collection of bathroom essentials designed to enhance both functionality and aesthetics. From stylish faucets and fixtures to luxurious shower systems, our range caters to diverse design preferences. Elevate your bathroom with sanitaryware that blends comfort and elegance, and enhance convenience with our carefully chosen accessories. Transform your space with bathroom vanities that combine style and organization. Complement your design with a variety of wall and floor tiles, offering endless possibilities. Whether for your home or commercial space, discover the perfect blend of form and function in our bathroom essentials.",
        img:image1,
        accordiantitle:'What are bathroom essentials?',
        accordiansummary:'Bathroom essentials are the fundamental items needed to make your bathroom functional and comfortable. These include faucets, showers, sanitaryware, accessories, and more.',
        accordiantitle1:'Why should I upgrade my bathroom essentials?',
        accordiansummary1:"Upgrading your bathroom essentials enhances both the aesthetics and functionality of your bathroom, making it a more enjoyable and efficient space.",      
        accordiantitle2:'What types of faucets do you offer?',
        accordiansummary2:'We offer a variety of faucet styles, including single-handle, dual-handle, wall-mounted, and more, to suit your design preferences.',
    },
]

export default Data;