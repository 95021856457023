import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contact.css';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Replace these with your EmailJS credentials
    const serviceID = 'service_8qecfuf';
    const templateID = 'template_ylozi2o';
    const userID = 'vyaaShHK1wGQAgaxn';

    emailjs
      .sendForm(serviceID, templateID, form.current, userID)
      .then(
        (result) => {
          console.log(result.text);
          toast.success('Email sent successfully!');
          form.current.reset();
        },
        (error) => {
          console.error(error.text);
          toast.error('Error sending email.');
        }
      );
  };

  return (
    <div className='cta-wrapper-contact'>
      <div className='cta-container'>
        <div className='cta-contact-location'>
        <h3>Location</h3>
        <h5><i className="bi bi-geo-alt-fill"></i>TAMIL NADU -  641301</h5>
          <p>2 Selvapuram,<br></br>
          Annur Rd, Jadayampalayam Pudur, <br></br>
          Chickadasampalayam, Tamil Nadu 641301<br></br>
          Phone: +91 90958 84488 ,+91 77088 98999<br></br>
          Email: rehobothtilesmtp@gmail.com
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d244.53696927088214!2d76.9567664719673!3d11.291409002733836!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8ef1591613bbd%3A0xf2b601a75ba65a3!2sHarsha%20Tiles%20Plus!5e0!3m2!1sen!2sin!4v1695720830068!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
      </div>

      <div className='cta-container' style={{ marginLeft: '30px' }}>
        <form ref={form} onSubmit={sendEmail}>
          <h3>Contact Us</h3>
          <div className="form-element">
            <input type="text" placeholder='Please Enter Your Name' name="name" required />
          </div>
          <div className="form-element">
            <input type="email" placeholder='Please Enter Your Email' name="email" required />
          </div>
          <div className="form-element">
            <input type="tel" placeholder='Please Enter Your Phone Number' name="phone" />
          </div>
          <div className="form-element">
            <textarea name="message" placeholder='Please Enter Your Message' required />
          </div>
          <div className="form-element">
            <input className="submit-btn" type="submit" value="Send Enquiry" />
          </div>
        </form>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
    
  );
};

export default Contact;
