import image1 from './card-images/Harsha/Harsha1 (1).webp'
import image2 from './card-images/Harsha/Harsha1 (2).webp'
import image3 from './card-images/Harsha/Harsha1 (3).webp'
import image4 from './card-images/Harsha/Harsha1 (4).webp'
import image5 from './card-images/Harsha/Harsha1 (5).webp'
import image6 from './card-images/Harsha/Harsha1 (6).webp'
import image7 from './card-images/Harsha/Harsha1 (7).webp'
import image8 from './card-images/Harsha/Harsha1 (8).webp'
import image9 from './card-images/Harsha/Harsha1 (9).webp'
import image10 from './card-images/Harsha/Harsha1 (10).webp'
import image11 from './card-images/Harsha/Harsha1 (11).webp'
import image12 from './card-images/Harsha/Harsha1 (12).webp'
import image13 from './card-images/Harsha/Harsha1 (13).webp'
import image14 from './card-images/Harsha/Harsha1 (14).webp'

import image15 from './card-images/Kajaria/kajaria (1).webp'
import image16 from './card-images/Kajaria/kajaria (2).webp'
import image17 from './card-images/Kajaria/kajaria (3).webp'
import image18 from './card-images/Kajaria/kajaria (4).webp'
import image19 from './card-images/Kajaria/kajaria (5).webp'
import image20 from './card-images/Kajaria/kajaria (6).webp'
import image21 from './card-images/Kajaria/kajaria (7).webp'
import image22 from './card-images/Kajaria/kajaria (8).webp'
import image23 from './card-images/Kajaria/kajaria (9).webp'
import image24 from './card-images/Kajaria/kajaria (10).webp'
import image25 from './card-images/Kajaria/kajaria (11).webp'
import image26 from './card-images/Kajaria/kajaria (12).webp'
import image27 from './card-images/Kajaria/kajaria (13).webp'
import image28 from './card-images/Kajaria/kajaria (14).webp'
import image29 from './card-images/Kajaria/kajaria (15).webp'

import image30 from './card-images/Nitco/Nitco (1).webp'
import image31 from './card-images/Nitco/Nitco (2).webp'
import image32 from './card-images/Nitco/Nitco (3).webp'
import image33 from './card-images/Nitco/Nitco (4).webp'
import image34 from './card-images/Nitco/Nitco (5).webp'
import image35 from './card-images/Nitco/Nitco (6).webp'
import image36 from './card-images/Nitco/Nitco (7).webp'
import image37 from './card-images/Nitco/Nitco (8).webp'
import image38 from './card-images/Nitco/Nitco (9).webp'
import image39 from './card-images/Nitco/Nitco (10).webp'
import image40 from './card-images/Nitco/Nitco (11).webp'
import image41 from './card-images/Nitco/Nitco (12).webp'
import image42 from './card-images/Nitco/Nitco (13).webp'
import image43 from './card-images/Nitco/Nitco (14).webp'
import image44 from './card-images/Nitco/Nitco (15).webp'

import image45 from './card-images/Simpolo/Simpolo (1).webp'
import image46 from './card-images/Simpolo/Simpolo (2).webp'
import image47 from './card-images/Simpolo/Simpolo (3).webp'
import image48 from './card-images/Simpolo/Simpolo (4).webp'
import image49 from './card-images/Simpolo/Simpolo (5).webp'
import image50 from './card-images/Simpolo/Simpolo (6).webp'
import image51 from './card-images/Simpolo/Simpolo (7).webp'
import image52 from './card-images/Simpolo/Simpolo (8).webp'
import image53 from './card-images/Simpolo/Simpolo (9).webp'
import image54 from './card-images/Simpolo/Simpolo (10).webp'
import image55 from './card-images/Simpolo/Simpolo (11).webp'
import image56 from './card-images/Simpolo/Simpolo (12).webp'
import image57 from './card-images/Simpolo/Simpolo (13).webp'
import image58 from './card-images/Simpolo/Simpolo (14).webp'

import image59 from './card-images/Somany/Somany (1).webp'
import image60 from './card-images/Somany/Somany (2).webp'
import image61 from './card-images/Somany/Somany (3).webp'
import image62 from './card-images/Somany/Somany (4).webp'
import image63 from './card-images/Somany/Somany (5).webp'
import image64 from './card-images/Somany/Somany (6).webp'
import image65 from './card-images/Somany/Somany (7).webp'
import image66 from './card-images/Somany/Somany (8).webp'
import image67 from './card-images/Somany/Somany (9).webp'
import image68 from './card-images/Somany/Somany (10).webp'
import image69 from './card-images/Somany/Somany (11).webp'
import image70 from './card-images/Somany/Somany (12).webp'
import image71 from './card-images/Somany/Somany (13).webp'
import image72 from './card-images/Somany/Somany (14).webp'
import image73 from './card-images/Somany/Somany (15).webp'

import image74 from './card-images/Jaquar/jaquar (1).webp'
import image75 from './card-images/Jaquar/jaquar (2).webp'
import image76 from './card-images/Jaquar/jaquar (3).webp'
import image77 from './card-images/Jaquar/jaquar (4).webp'
import image78 from './card-images/Jaquar/jaquar (5).webp'
import image79 from './card-images/Jaquar/jaquar (6).webp'
import image80 from './card-images/Jaquar/jaquar (7).webp'
import image81 from './card-images/Jaquar/jaquar (8).webp'
import image82 from './card-images/Jaquar/jaquar (9).webp'
import image83 from './card-images/Jaquar/jaquar (10).webp'
import image84 from './card-images/Jaquar/jaquar (11).webp'
import image85 from './card-images/Jaquar/jaquar (12).webp'
import image86 from './card-images/Jaquar/jaquar (13).webp'

import image87 from './card-images/Kerovit/kerovit (1).webp'
import image88 from './card-images/Kerovit/kerovit (2).webp'
import image89 from './card-images/Kerovit/kerovit (3).webp'
import image90 from './card-images/Kerovit/kerovit (4).webp'
import image91 from './card-images/Kerovit/kerovit (5).webp'
import image92 from './card-images/Kerovit/kerovit (6).webp'
import image93 from './card-images/Kerovit/kerovit (7).webp'
import image94 from './card-images/Kerovit/kerovit (8).webp'
import image95 from './card-images/Kerovit/kerovit (9).webp'
import image96 from './card-images/Kerovit/kerovit (10).webp'
import image97 from './card-images/Kerovit/kerovit (11).webp'
import image98 from './card-images/Kerovit/kerovit (12).webp'
import image99 from './card-images/Kerovit/kerovit (13).webp'

const data =  [

    {
      id:1,
      img:image1,
      tag:"Harsha"
  
    },
    {
      id:2,
      img:image2,
      tag:"Harsha"
    },
    { 
      id:3,
      img:image3,
      tag:"Harsha"
    },
    {
      id:4,
      img:image4,
      tag:"Harsha"
    },
    {
      id:5,
      img:image5,
      tag:"Harsha"
    },
    {
      id:6,
      img:image6,
      tag:"Harsha"
    },
    {
      id:7,
      img:image7,
      tag:"Harsha"
    },
    {
      id:8,
      img:image8,
      tag:"Harsha"
    },
    {
      id:9,
      img:image9,
      tag:"Harsha"
    },
    {
      id:10,
      img:image10,
      tag:"Harsha"
    },
    {
      id:11,
      img:image11,
      tag:"Harsha"
    },
    {
      id:12,
      img:image12,
      tag:"Harsha"
    },
    {
      id:13,
      img:image13,
      tag:"Harsha"
    },
    {
      id:14,
      img:image14,
      tag:"Harsha"
    },
    {
      id:15,
      img:image15,
      tag:"kajaria"
    },
    {
      id:16,
      img:image16,
      tag:"kajaria"
    },
    {
      id:17,
      img:image17,
      tag:"kajaria"
    },
    {
      id:18,
      img:image18,
      tag:"kajaria"
    },
    {
      id:19,
      img:image19,
      tag:"kajaria"
    },
    {
      id:20,
      img:image20,
      tag:"kajaria"
    },
    {
      id:21,
      img:image21,
      tag:"kajaria"
    },
    {
      id:22,
      img:image22,
      tag:"kajaria"
    },
    {
      id:23,
      img:image23,
      tag:"kajaria"
    },
    {
      id:24,
      img:image24,
      tag:"kajaria"
    },
    {
      id:25,
      img:image25,
      tag:"kajaria"
    },
    {
      id:26,
      img:image26,
      tag:"kajaria"
    },
    {
      id:27,
      img:image27,
      tag:"kajaria"
    },
    {
      id:28,
      img:image28,
      tag:"kajaria"
    },
    {
      id:29,
      img:image29,
      tag:"kajaria"
    },
    {
      id:30,
      img:image30,
      tag:"Nitco"
    },
    {
      id:31,
      img:image31,
      tag:"Nitco"
    },
    {
      id:32,
      img:image32,
      tag:"Nitco"
    },
    {
      id:33,
      img:image33,
      tag:"Nitco"
    },
    {
      id:34,
      img:image34,
      tag:"Nitco"
    },
    {
      id:35,
      img:image35,
      tag:"Nitco"
    },
    {
      id:36,
      img:image36,
      tag:"Nitco"
    },
    {
      id:37,
      img:image37,
      tag:"Nitco"
    },
    {
      id:38,
      img:image38,
      tag:"Nitco"
    },
    {
      id:39,
      img:image39,
      tag:"Nitco"
    },
    {
      id:40,
      img:image40,
      tag:"Nitco"
    },
    {
      id:41,
      img:image41,
      tag:"Nitco"
    },
    {
      id:42,
      img:image42,
      tag:"Nitco"
    },
    {
      id:43,
      img:image43,
      tag:"Nitco"
    },
    {
      id:44,
      img:image44,
      tag:"Nitco"
    },
    {
      id:45,
      img:image45,
      tag:"Simpolo"
    },
    {
      id:46,
      img:image46,
      tag:"Simpolo"
    },
    {
      id:47,
      img:image47,
      tag:"Simpolo"
    },
    {
      id:48,
      img:image48,
      tag:"Simpolo"
    },
    {
      id:49,
      img:image49,
      tag:"Simpolo"
    },
    {
      id:50,
      img:image50,
      tag:"Simpolo"
    },
    {
      id:51,
      img:image51,
      tag:"Simpolo"
    },
    {
      id:52,
      img:image52,
      tag:"Simpolo"
    },
    {
      id:53,
      img:image53,
      tag:"Simpolo"
    },
    {
      id:54,
      img:image54,
      tag:"Simpolo"
    },
    {
      id:55,
      img:image55,
      tag:"Simpolo"
    },
    {
      id:56,
      img:image56,
      tag:"Simpolo"
    },
    {
      id:57,
      img:image57,
      tag:"Simpolo"
    },
    {
      id:58,
      img:image58,
      tag:"Simpolo"
    },
    {
      id:59,
      img:image59,
      tag:"Somany"
    },
    {
      id:60,
      img:image60,
      tag:"Somany"
    },
    {
      id:61,
      img:image61,
      tag:"Somany"
    },
    {
      id:62,
      img:image62,
      tag:"Somany"
    },
    {
      id:63,
      img:image63,
      tag:"Somany"
    },
    {
      id:64,
      img:image64,
      tag:"Somany"
    },
    {
      id:65,
      img:image65,
      tag:"Somany"
    },
    {
      id:66,
      img:image66,
      tag:"Somany"
    },
    {
      id:67,
      img:image67,
      tag:"Somany"
    },
    {
      id:68,
      img:image68,
      tag:"Somany"
    },
    {
      id:69,
      img:image69,
      tag:"Somany"
    },
    {
      id:70,
      img:image70,
      tag:"Somany"
    },
    {
      id:71,
      img:image71,
      tag:"Somany"
    },
    {
      id:72,
      img:image72,
      tag:"Somany"
    },
    {
      id:73,
      img:image73,
      tag:"Somany"
    },
    {
      id:74,
      img:image74,
      tag:"jaquar"
    },
    {
      id:75,
      img:image75,
      tag:"jaquar"
    },
    {
      id:76,
      img:image76,
      tag:"jaquar"
    },
    {
      id:77,
      img:image77,
      tag:"jaquar"
    },
    {
      id:78,
      img:image78,
      tag:"jaquar"
    },
    {
      id:79,
      img:image79,
      tag:"jaquar"
    },
    {
      id:80,
      img:image80,
      tag:"jaquar"
    },
    {
      id:81,
      img:image81,
      tag:"jaquar"
    },
    {
      id:82,
      img:image82,
      tag:"jaquar"
    },
    {
      id:83,
      img:image83,
      tag:"jaquar"
    },
    {
      id:84,
      img:image84,
      tag:"jaquar"
    },
    {
      id:85,
      img:image85,
      tag:"jaquar"
    },
    {
      id:86,
      img:image86,
      tag:"jaquar"
    },
    {
      id:87,
      img:image87,
      tag:'kerovit'
    },
    {
      id:88,
      img:image88,
      tag:'kerovit'
    },
    {
      id:89,
      img:image89,
      tag:'kerovit'
    },
    {
      id:90,
      img:image90,
      tag:'kerovit'
    },
    {
      id:91,
      img:image91,
      tag:'kerovit'
    },
    {
      id:92,
      img:image92,
      tag:'kerovit'
    },
    {
      id:93,
      img:image93,
      tag:'kerovit'
    },
    {
      id:94,
      img:image94,
      tag:'kerovit'
    },
    {
      id:95,
      img:image95,
      tag:'kerovit'
    },
    {
      id:96,
      img:image96,
      tag:'kerovit'
    },
    {
      id:97,
      img:image97,
      tag:'kerovit'
    },

    {
      id:98,
      img:image98,
      tag:'kerovit'
    },
    {
      id:99,
      img:image99,
      tag:'kerovit'
    },
 
   
  ]
  
  export default data;


