import React, { useState } from 'react';
import './style.css';
import data from './data';

const Gallery = () => {
  const [filter, setFilter] = useState('all');
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [isActive, setActive] = useState(false); // State variable for active class
  const images = data;

  const filteredImages = filter === 'all' ? images : images.filter((image) => image.tag === filter);

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  const prevImage = () => {
    const currentIndex = filteredImages.findIndex((image) => image.id === fullscreenImage.id);
    if (currentIndex > 0) {
      setFullscreenImage(filteredImages[currentIndex - 1]);
    }
  };

  const nextImage = () => {
    const currentIndex = filteredImages.findIndex((image) => image.id === fullscreenImage.id);
    if (currentIndex < filteredImages.length - 1) {
      setFullscreenImage(filteredImages[currentIndex + 1]);
    }
  };

  // Toggle the isActive state when clicking on "BRANDS" option
  const toggleActive = () => {
    setActive(!isActive);
  };

  return (
    <>
      <div className="Main">
        <div className={`sidenavbar ${isActive ? 'active' : ''}`}>
          <div className='wrapper'>
            <li onClick={() => { setFilter('all'); setActive(false); }}>All</li>
            <li onClick={() => { setFilter('Harsha'); setActive(false); }}>Harsha Tiles</li>
            <li onClick={() => { setFilter('kajaria'); setActive(false); }}>Kajaria</li>
            <li onClick={() => { setFilter('Nitco'); setActive(false); }}>Nitco</li>
            <li onClick={() => { setFilter('Somany'); setActive(false); }}>Somany</li>
            <li onClick={() => { setFilter('Simpolo'); setActive(false); }}>Simpolo</li>
            <li onClick={() => { setFilter('jaquar'); setActive(false); }}>Jaquar</li>
            <li onClick={() => { setFilter('kerovit'); setActive(false); }}>Kerovit</li>
            <li onClick={toggleActive} className={`brand-dropdown ${isActive ? 'active' : ''}`}>
              BRANDS ↓
            </li>
          </div>
        </div>

        <div className='wrapper-gallery'>
          <div className="image-container">
            {filteredImages.map((image) => (
              <div key={image.id}>
                <img
                  src={image.img}
                  alt={image.p}
                  onClick={() => openFullscreen(image)}
                  className="fullscreen-trigger"
                />
              </div>
            ))}
          </div>
        </div>

        {fullscreenImage && (
          <div className="fullscreen">
            <div className="fullscreen-content">
              <span className="close" onClick={closeFullscreen}>
                &times;
              </span>
              <button className="control left" onClick={prevImage}>
                &lt; 
              </button>
              <button className="control right" onClick={nextImage}>
                 &gt;
              </button>
              <div>
                <img src={fullscreenImage.img} alt={fullscreenImage.p} />
                <p>{fullscreenImage.p}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Gallery;
