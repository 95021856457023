import './about.css'
import image from './image.webp'
const  About = () => {

    return (
      <>
      <h2 className='cta-text-about'>Why Choose Rehoboth Shelters for Your Bathroom Essentials?</h2>
      <div className='wrapper_1' >
        
          <div className=".contain-1">
         
              <div className="cta-text-1">
                  <h4 className='cta-taxt-h4-about'>Premium Selection:</h4>
                  <p className='cta-taxt-p-about'>Our curated collection includes an array of bathroom essentials and accessories to suit diverse preferences and design aesthetics.<br></br> From classic to modern, we offer products that blend seamlessly with your bathroom decor.</p>
              </div>
              <div className="cta-text-2">
                  <h4 className='cta-taxt-h4-about'>Quality Assurance:</h4>
                  <p className='cta-taxt-p-about'> We prioritize quality above all else. When you choose Rehoboth Shelters, you can trust that you're investing in products that are not only stylish but also built to last. Our brands, Jaquar and Kerovit, are known for their superior craftsmanship and durability.</p>
              </div>
              <div className="cta-text-3">
                  <h4 className='cta-taxt-h4-about'>Expert Advice:</h4>
                  <p className='cta-taxt-p-about'>Our knowledgeable team is at your service to provide expert guidance in selecting the right bathroom essentials and accessories for your specific needs. We understand that every bathroom is unique, and we're here to assist you in making informed choices.</p>
              </div>
          </div>
          <div className='cta-image'>
            <img src={image}></img>
          </div>
         
      </div>
      </>
    );
  };
  
  export default About;